// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-previews-pagina-js": () => import("./../../../src/pages/previews/pagina.js" /* webpackChunkName: "component---src-pages-previews-pagina-js" */),
  "component---src-pages-previews-post-js": () => import("./../../../src/pages/previews/post.js" /* webpackChunkName: "component---src-pages-previews-post-js" */),
  "component---src-templates-areas-js": () => import("./../../../src/templates/areas.js" /* webpackChunkName: "component---src-templates-areas-js" */),
  "component---src-templates-clinical-staff-js": () => import("./../../../src/templates/clinical-staff.js" /* webpackChunkName: "component---src-templates-clinical-staff-js" */),
  "component---src-templates-exam-js": () => import("./../../../src/templates/exam.js" /* webpackChunkName: "component---src-templates-exam-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-pages-areas-de-atuacao-js": () => import("./../../../src/templates/pages/areas-de-atuacao.js" /* webpackChunkName: "component---src-templates-pages-areas-de-atuacao-js" */),
  "component---src-templates-pages-contato-js": () => import("./../../../src/templates/pages/contato.js" /* webpackChunkName: "component---src-templates-pages-contato-js" */),
  "component---src-templates-pages-convenios-js": () => import("./../../../src/templates/pages/convenios.js" /* webpackChunkName: "component---src-templates-pages-convenios-js" */),
  "component---src-templates-pages-corpo-clinico-js": () => import("./../../../src/templates/pages/corpo-clinico.js" /* webpackChunkName: "component---src-templates-pages-corpo-clinico-js" */),
  "component---src-templates-pages-exames-js": () => import("./../../../src/templates/pages/exames.js" /* webpackChunkName: "component---src-templates-pages-exames-js" */),
  "component---src-templates-pages-index-js": () => import("./../../../src/templates/pages/index.js" /* webpackChunkName: "component---src-templates-pages-index-js" */),
  "component---src-templates-pages-onde-fazer-exames-js": () => import("./../../../src/templates/pages/onde-fazer-exames.js" /* webpackChunkName: "component---src-templates-pages-onde-fazer-exames-js" */),
  "component---src-templates-pages-parceria-inovacao-js": () => import("./../../../src/templates/pages/parceria-inovacao.js" /* webpackChunkName: "component---src-templates-pages-parceria-inovacao-js" */),
  "component---src-templates-pages-sobre-js": () => import("./../../../src/templates/pages/sobre.js" /* webpackChunkName: "component---src-templates-pages-sobre-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-post-list-js": () => import("./../../../src/templates/post-list.js" /* webpackChunkName: "component---src-templates-post-list-js" */)
}

